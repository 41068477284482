<template>
  <div id="App">
    <div class="Main Login" v-if="$route.name == 'login'">
      <el-main>
        <router-view></router-view>
      </el-main>
    </div>
    <div id="Main" v-else>
      <el-container class="main">
        <Menu></Menu>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
  </div>
</template>
<script>
import Menu from "./components/Menu/Menu";

export default {
  name: "app",
  components: {
    Menu,
  },
};
</script>