require('es6-promise').polyfill();
require('isomorphic-fetch');

const api = "https://api.hundredkitstudio.com";

let Fetch = async (url, option = {}) => {
    const token = (() => {
        let token = sessionStorage.getItem("token");
        if (!token) {
            token = localStorage.getItem("token");
        }

        return token;
    })();

    // 接口请求超时的处理
    let timeout = null;

    // 請求頭
    option.headers = {};

    // Authorization
    if (token) {
        option.headers['Authorization'] = "Bearer " + token;
    }

    const m = (option.method || 'GET').toLocaleLowerCase();
    if (m === 'get') {
        if (option.query) {
            url = formatUrl(url, option.query);
        }
    }

    if (m === 'post' || m === 'put' || m === 'patch') {
        if (url !== '/upload') {
            option.headers['Content-Type'] = 'application/json';
            option.headers['Accept-Language'] = 'zh-CN';
            option.body = JSON.stringify(option.body);
        }
    }

    return new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
            reject(`網絡請求時間異常：${url}`);
            clearTimeout(timeout);
        }, 15000);

        let status = '';
        url = '/' + url;

        fetch(api + url, option)
            .then(response => {
                clearTimeout(timeout);
                status = response.status;
                return response.json();
            })
            .then(res => {
                res.isOk = status === 200;

                // 后端token失效
                if (status === 401) {
                    Fetch.tokenLose && Fetch.tokenLose(res.message);
                    reject(res.message);
                }

                resolve(res);
            })
            .catch(err => {
                if (!status) {
                    reject('網絡錯誤');
                } else {
                    reject(err.message);
                }
            });
    });
};

function formatUrl(url, query) {
    if (!query || !Object.keys(query).length) {
        return url;
    }

    let params = Object.keys(query)
        .filter(x => query[x] !== '')
        .map(x => {
            return `${x}=${query[x]}`;
        })
        .join('&');

    return `${url}?${params}`;
}

export default Fetch;
