export default {
    state: {
        currentPath: "/",
        autoListUpdate: null,
    },

    mutations: {
        ["common/currentPath"](state, path) {
            state.currentPath = path;
        },

        ["auto/update"](state, value) {
            state.autoListUpdate = value;
        }
    },

    actions: {
        ["common/currentPath"](context, path) {
            context.commit("common/currentPath", path);
        },

        ["auto/update"](context, value) {
            context.commit("auto/update", value);
        }
    }
};