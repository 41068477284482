import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const login = (r) => require.ensure([], () => r(require('./pages/Login')), 'login');

const profile = (r) => require.ensure([], () => r(require('./pages/Profile')), 'profile');

const comment = (r) => require.ensure([], () => r(require('./pages/Comment')), 'comment');

const room = (r) => require.ensure([], () => r(require('./pages/Room')), 'room');
const roomCreator = (r) => require.ensure([], () => r(require('./pages/Room/Creator')), 'roomCreator');
const roomEditor = (r) => require.ensure([], () => r(require('./pages/Room/Creator')), 'roomEditor');

const auto = (r) => require.ensure([], () => r(require('./pages/Auto')), 'auto');
const autoCreator = (r) => require.ensure([], () => r(require('./pages/Auto/Creator')), 'autoCreator');

const notFound = (r) => require.ensure([], () => r(require('./pages/NotFound')), 'notFound');

const routes = [
    {
        path: "/",
        name: "home",
        redirect: {
            name: "comment"
        }
    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    {
        path: "/profile",
        name: "profile",
        component: profile
    },
    {
        path: "/comment",
        name: "comment",
        component: comment
    },
    {
        path: "/room/create",
        name: "roomCreator",
        component: roomCreator
    },
    {
        path: "/room/edit/:id",
        name: "roomEditor",
        component: roomEditor
    },
    {
        path: "/room/:id",
        name: "room",
        component: room
    },
    {
        path: "/auto",
        name: "auto",
        component: auto
    },
    {
        path: "/auto/:id",
        name: "autoCreator",
        component: autoCreator
    },
    {
        path: "*",
        name: "notFound",
        component: notFound
    }
];

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes
});

export default router;