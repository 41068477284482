import Vue from "vue";

// element-ui import
import "./plugins/element.js";

// Page Render
import App from "./App.vue";

// Router
import router from "./router";

// Vuex
import store from "./store";

import Fetch from "./utils/fetch";

// Style
import "./assets/style/global.less";

Vue.config.productionTip = false;

Vue.prototype.$fetch = Fetch;

new Vue({
  render: h => h(App),
  router,
  store,
  created() {
    // 登录超时
    Fetch.tokenLose = err => {
      this.$message({
        message: err,
        type: 'warning'
      });

      this.$router.push({ path: "/login" });
    };

    // 系统错误
    Fetch.error = err => {
      this.$message({
        message: err,
        type: 'warning'
      });
    };
  }
}).$mount("#app")
