<template>
  <el-aside width="60px" class="menu">
    <router-link class="menu-item" to="/profile">
      <el-tooltip
        class="item"
        effect="dark"
        content="個人資料"
        placement="left"
      >
        <el-avatar :size="'large'" :src="logo"></el-avatar>
      </el-tooltip>
    </router-link>

    <router-link class="menu-item" to="/comment">
      <el-tooltip
        class="item"
        effect="dark"
        content="在線評論"
        placement="left"
      >
        <i
          :class="
            path.indexOf('/comment') == 0 || path.indexOf('/room') == 0
              ? 'el-icon-user-solid'
              : 'el-icon-user'
          "
        ></i>
      </el-tooltip>
    </router-link>

    <router-link class="menu-item" to="/auto">
      <el-tooltip
        class="item"
        effect="dark"
        content="自動回復設定"
        placement="left"
      >
        <i
          :class="
            path.indexOf('/auto') == 0 ? 'el-icon-s-goods' : 'el-icon-goods'
          "
        ></i>
      </el-tooltip>
    </router-link>
  </el-aside>
</template>
<script>
import logo from "../../assets/logo.png";

export default {
  name: "Menu",

  data() {
    return {
      logo,
      path: null,
    };
  },

  created() {
    this.init();
  },

  watch: {
    "$route.path"(path) {
      this.path = path;
    },
  },

  methods: {
    init() {
      this.getCurrentPath();
    },

    getCurrentPath() {
      this.path = this.$route.path;
    },
  },
};
</script>
<style scoped lang="less">
.menu {
  padding: 25px 0;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: @blue;
  border-radius: 4px 0 0 4px;

  .menu-item {
    margin: 0 0 25px 0;
    font-size: 25px;
    color: #fff;

    &:hover {
      color: #ddd;
    }
  }
}
</style>